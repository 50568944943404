module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"RJ Cantrell","short_name":"RJCantrell","description":"Personal website of RJ Cantrell, Colorado-based AI/ML engineer and leader","start_url":"/","background_color":"#1a1a1d","theme_color":"#e6e6ea","display":"standalone","icon":"static/web-app-manifest-512x512.png","icons":[{"src":"favicon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"/web-app-manifest-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/web-app-manifest-512x512.png","sizes":"512x512","type":"image/png"},{"src":"favicon.ico","sizes":"64x64 32x32 24x24 16x16","type":"image/x-icon"},{"src":"favicon.svg","sizes":"64x64 32x32 24x24 16x16","type":"image/svg+xml"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e467f4f368f1fb30bdd8236ca5987102"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
